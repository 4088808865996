<template>
  <div>
    <common-top title="微力说明"></common-top>
    <div>
      <div class="weili-instructions">
        <div v-html="content"></div>
      </div>
      <div v-if="!content" class="null">暂无说明</div>
    </div>
  </div>
</template>

<script>

import commonTop from "../../components/commonTop.vue";
import {
  wxConPost,

} from "../../api/httpApi";
export default {
  props: {

  },
  data () {
    return {
      content: "",
    };
  },
  computed: {

  },
  created () {
    this.getContentData();
  },
  mounted () {

  },
  watch: {

  },
  methods: {
    async getContentData () {
      const ret = await wxConPost(
        "/services/gttt-wxstats-mgt/weili/getWeiliListShuoM",
        { id: 1 }
      );
      this.content = ret.ret[0].content;
    }
  },
  components: {
    commonTop,
  },
};
</script>

<style scoped >
.null {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 20;
  color: #9e9e9e;
}
.weili-instructions {
  padding: 20px 17px;
}
</style>
